html {
  height: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  font-size: 1.8rem;
  color: #fff;
  font-family: $roboto;
  background-color: #000;
  &.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    height: 100% !important;
  }
}
img {
  max-width: 100% !important;
}
#root {
  position: relative;
  min-height: 100%;
}
button {
  outline: none !important;
}
a {
  color: $yellow;
  transition: color .25s ease;
  outline: none !important;
  &:hover {
    color: lighten($yellow, 20%);
    text-decoration: none;
  }
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: 1rem;
  padding-right: 1rem;
}
.container {
  padding-left: 1rem;
  padding-right: 1rem;
}
.row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.container {
  max-width: 100% !important;
}
.swal2-shown,
.ReactModal__Body--open {
	#root {
		//filter: blur(5px);
	}
}
.swal2-container.swal2-backdrop-show {
  background: url(/images/pop-bg.jpg) no-repeat !important;
  background-size: 100% 100% !important;
  .swal2-popup {
    display: flex;
  }
}
.swal2-container {
  .swal2-popup {
		height: auto !important;
    width: 60% !important;
    min-height: 40% !important;
		background: url(#{$cdnUrl}/images/popup-bg.png) no-repeat center bottom;
		background-size: 100% 100%;
    border: 0;
    padding-top: 0;
    padding-bottom: 3rem;
    border-radius: 0 !important;
    @include min-screen(1200px) {
      width: 46% !important;
      min-height: 50vh !important;
    }
    &.popup-img {
      width: 64% !important;
      background: transparent !important;
      padding: 0;
      #swal2-content {
        margin-top: 0;
        margin-bottom: 0;
      }
      .swal2-actions {
        bottom: -2rem;
      }
    }
    .swal2-close,
    .close {
      position: absolute;
      width: 5rem;
      height: 5rem;
      background: url(#{$cdnUrl}/images/close.png) no-repeat center;
      background-size: 100%;
      top: -5rem;
      right: -5rem;
      opacity: 1;
      font-size: 4rem;
      outline: none !important;
      color: #fff !important;
      font-family: serif;
      font-weight: 300;
      z-index: 9;
      text-indent: -9999px;
    }
    #swal2-content {
      font-size: 2.2rem;
			margin-bottom: 2rem;
      margin-top: 3rem;
      color: #fff;
      line-height: 150%;
      text-transform: uppercase;
      p {
        font-size: 2.2rem;
        color: #fff;
        line-height: 150%;
        text-transform: uppercase;
      }
    }
    #swal2-title,
    h2 {
      line-height: 120%;
      font-size: tovw(60);
      text-align: center;
      text-transform: uppercase;
      @extend .text-gradient;
    }
  }
}
.ReactModal__Overlay {
  display: flex!important;
  position: fixed!important;
  top: 0!important;
  right: 0!important;
  bottom: 0 !important;
  left: 0!important;
  flex-direction: row!important;
  align-items: center!important;
  justify-content: center!important;
  padding: 10px!important;
  background: url(/images/pop-bg.jpg) no-repeat !important;
  background-size: 100% 100% !important;
  z-index: 999!important;
  overflow-x: hidden!important;
  -webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  font-size: 1rem;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0!important;
  width: 60% !important;
  min-height: 30%;
  background: url(#{$cdnUrl}/images/popup-bg.png) no-repeat center bottom;
  background-size: 100% 100%;
  border: 0 !important;
  padding: 1vw 0 !important;
  text-align: center;
  outline: none;
  @include min-screen(1200px) {
    width: 46% !important;
  }
  .modal-description {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  &.modal-vote {
    h2 {
      font-size: 3rem;
    }
  }
  .close {
    position: absolute;
    width: 5rem;
    height: 5rem;
    background: url(#{$cdnUrl}/images/close.png) no-repeat center;
    background-size: 100%;
    top: -5rem;
    right: -5rem;
    opacity: 1;
    font-size: 4rem;
    outline: none !important;
    color: #fff !important;
    font-family: serif;
    font-weight: 300;
    z-index: 9;
    text-indent: -9999px;
  }
  h2 {
    line-height: 120%;
    font-size: tovw(60);
    text-align: center;
    text-transform: uppercase;
  }
	p,
	li {
    font-size: 2rem;
  }
  &.modal-prize--3 {
    p,
    li {
      font-size: 1.6rem !important;
    }
  }
}
#main-body {
  position: relative;
}
.main-content {
  position: relative;
  padding-top: tovw(145);
  z-index: 1;
  overflow-x: hidden;
  &:before {
    content: "";
    position: fixed;
    background: url(/images/page-bg.jpg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
}
.ScrollbarsCustom-Wrapper {
  right: 0 !important;
}
.ScrollbarsCustom-Track {
  width: 5px !important;
  right: -8px !important;
  z-index: 1;
  background: transparent !important;
}
.ScrollbarsCustom-Thumb {
  background: #fff !important;
}
#header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: tovw(434);
    height: tovw(149);
    background: url(/images/tie-top.png);
    background-size: 100% 100%;
    backface-visibility: hidden;
  }
}
.logo {
  height: tovw(82);
  position: absolute;
  top: 1vh;
  left: 2vh;
  img {
    display: inline-block;
    height: 100%;
  }
}
.login-area {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  padding-top: 1vw;
  a {
    display: inline-block;
    color: $dark;
    font-size: tovw(38);
    text-transform: uppercase;
    margin-right: 2rem;
    margin-left: 2rem;
    vertical-align: middle;
    &:nth-child(3) {
      margin-right: 0;
    }
    &:hover {
      color: $yellow;
    }
  }
  .line {
    display: inline-block;
    width: tovw(8);
    height: tovw(45);
    background: url(/images/line-menu.png);
    background-size: 100% 100%;
    backface-visibility: hidden;
    vertical-align: middle;
  }
  .login-url {
    color: #fff;
    padding-top: .5vw;
    padding-bottom: .5vw;
    min-width: tovw(250);
    text-align: center;
    position: relative;
    margin-right: 0;
    margin-left: .3vw;
    overflow: hidden;
    font-size: 1.5vw;
    z-index: 1;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: skew(20deg);
      background: $dark;
      transform-origin: top right;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
}
.title-big {
  line-height: 100%;
  font-size: tovw(190);
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 1vw;
  text-shadow: 0 .1vw .2vw rgba(0, 0, 0, .2);
  &--cc {
    padding-left: 1vw;
    padding-top: 5vw;
    line-height: 90%;
  }
}
.section-featured {
  margin-bottom: 2vw;
  .col {
    &--title {
      flex: 0 0 30%;
      max-width: 30%;
      padding-left: 1vw;
      z-index: 2;
    }
    &--content {
      flex: 0 0 70%;
      max-width: 70%;
    }
  }
}
.slider {
  .slide {
    img {
      display: block;
      width: 100%;
    }
    > div {
      position: relative;
      width: 100%;
      padding-bottom: 48.6%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}
.swiper-pagination  {
  position: absolute !important;
  width: 100% !important;
  text-align: center !important;
  left: 0 !important;
  bottom: .5vw !important;
  .swiper-pagination-bullet {
    width: tovw(26) !important;
    height: tovw(22) !important;
    background: url(/images/tri.png) no-repeat !important;
    background-size: 100% 100% !important;
    display: inline-block !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    border-radius: 0 !important;
    opacity: 1 !important;
    &.swiper-pagination-bullet-active {
      background: url(/images/tri-active.png) no-repeat !important;
      background-size: 100% 100% !important;
    }
  }
}
.section {
  position: relative;
  margin-bottom: 4vw;
}
.section-content {
  position: relative;
  z-index: 1;
  padding-top: 1vw;
  padding-bottom: 1vw;
  &:before {
    content: "";
    position: absolute;
    width: 105%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(254,112,70);
    background: -moz-linear-gradient(0deg, rgba(254,112,70,1) 0%, rgba(249,206,39,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(254,112,70,1) 0%, rgba(249,206,39,1) 100%);
    background: linear-gradient(0deg, rgba(254,112,70,1) 0%, rgba(249,206,39,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fe7046",endColorstr="#f9ce27",GradientType=1);
    transform: skew(34deg);
    transform-origin: bottom left;
  }
  &--reverse {
    .section-content__title {
      padding-left: 3.5vw;
    }
    &:before {
      transform: skew(-34deg);
      transform-origin: bottom right;
      left: auto;
      right: 0;
    }
  }
  &__title {
    padding-left: 1.5vw;
    font-size: tovw(102);
    line-height: 100%;
    text-transform: uppercase;
  }
  p {
    line-height: 120%;
    color: $dark;
    font-size: tovw(25);
  }
}
.wrap-btns-long {
  a {
    margin-bottom: .5vw;
    &:nth-child(2) {
      margin-left: 4vw;
    }
    &:nth-child(3) {
      margin-left: 8vw;
    }
  }
}
.icon-arrow {
  display: inline-block;
  vertical-align: middle;
  width: tovw(41);
  height: tovw(43);
  background: url(/images/arrow-right.png) no-repeat !important;
  background-size: 100% 100% !important;
  backface-visibility: hidden;
  margin-left: 1vw;
  transform: translateY(.2vw);
  filter: drop-shadow(0 .1vw .2vw rgba(0, 0, 0, .2));
  &--big {
    width: tovw(76);
    height: tovw(82);
    margin-left: -1.5vw;
  }
}
.text-gradient {  
  background: rgb(161,255,180);
  background: -moz-linear-gradient(0deg, rgba(161,255,180,1) 0%, rgba(42,255,236,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(161,255,180,1) 0%, rgba(42,255,236,1) 100%);
  background: linear-gradient(0deg, rgba(161,255,180,1) 0%, rgba(42,255,236,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a1ffb4",endColorstr="#2affec",GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.cover {
  display: inline-block;
  width: tovw(447);
  background: url(/images/cover.png) no-repeat;
  background-size: 100% 100%;
  padding: 2vw 1vw;
  text-align: center;
  &__title {
    text-transform: uppercase;
    font-size: tovw(32);
    text-align: center;
    margin-bottom: 1.5vw;
    @extend .text-gradient;
  }
  &__description {
    margin-bottom: 1vw;
    text-align: left;
  }
  &__img {
    display: inline-block;
    width: tovw(172);
    height: tovw(172);
    position: relative;
    margin-bottom: .5vw;
    z-index: 1;
    img {
      border-radius: 0 1.2vw 0 0;
      display: inline-block;
      width: 100%;
    }
    &:before {
      content: "";
      position: absolute;
      width: 108%;
      height: 108%;
      background: url(/images/profile-cover.png);
      background-size: 100% 100%;
      z-index: -1;
      left: -2%;
      top: -2%;
    }
  }
  &--winner {
    width: tovw(609);
    background: url(/images/winner-cover.png) no-repeat;
    background-size: 100% 100%;
    padding: 2vw 4vw;
    h2 {
      font-size: tovw(89);
      text-transform: uppercase;
      line-height: 100%;
    }
    > h2 {
      white-space: nowrap;
      font-size: tovw(80);
      margin-bottom: .5vw;
    }
    .cover__avatar {
      width: 75%;
      margin: auto;
    }
  }
}
.video-thumb {
  position: relative;
  display: inline-block;
  img {
    width: 100%;
  }
  &:before {
    content: "";
    width: tovw(80);
    height: tovw(80);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(/images/icon-play.png) no-repeat;
    background-size: 100% 100%;
  }
}
.section--partners {
  position: relative;
  padding-bottom: 6vw;
}
.title-partner {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: 64%;
  left: 2%;
}
.sub-title {
  padding-bottom: .5vw;
  font-size: tovw(68);
  color: $dark;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 1vw;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 80%;
    background: $dark;
    bottom: 0;
    left: 0;
  }
}
.video-inspiring {
  padding-left: 2vw;
  .video-thumb {
    margin-bottom: 1vw;
  }
}
.title-register-countdown {
  position: absolute;
  width: 135%;
  right: 2vw;
  top: 0;
}
.pr3 {
  padding-right: 3vw;
}
.register-countdown {
  position: absolute;
  font-size: tovw(90);
  left: 50%;
  transform: translateX(-50%);
  bottom: 9%;
  color: $dark;
  z-index: 2;
  pointer-events: none;
  line-height: 100%;
  text-transform: uppercase;
}
.title-submit-countdown {
  width: tovw(605);
  margin-bottom: .6vw;
  img {
    display: inline-block;
    width: 100%;
  }
}
.submit-countdown {
  padding-left: 3vw !important;
}
.section-content-submit {
  padding-top: 2vw;
  padding-bottom: 2vw;
}
.countdown-submittion {
  color: $dark;
  font-size: tovw(120);
  text-transform: uppercase;
  line-height: 100%;
}
.title-your-video {
  text-transform: uppercase;
  color: $dark;
  font-size: tovw(80);
  line-height: 100%;
}
.wrap-videos {
  padding-left: 3vw;
  padding-right: 3vw;
  .cover {
    width: 100%;
    margin-bottom: 1.5vw;
    &__img {
      margin-bottom: 1.5vw;
    }
    &__title {
      text-align: left;
      margin-bottom: .5vw;
    }
  }
}
.voting-countdown {
  display: block;
  background: url(/images/vote-count-bg.png);
  background-size: 100% 100%;
  text-align: center;
  padding-left: 8%;
  padding-right: 2%;
  padding-top: .5vw;
  padding-bottom: .2vw;
  width: tovw(406);
  margin-left: auto;
  margin-bottom: .5vw;
  h4 {
    font-size: tovw(30);
    text-transform: uppercase;
    color: $dark;
    line-height: 100%;
  }
  span {
    font-size: tovw(50);
    line-height: 100%;
    color: #fff;
    text-transform: uppercase;
  }
}
.form-search {
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $dark;
    transform: skew(-32deg);
    transform-origin: bottom right;
  }
  input {
    display: block;
    width: 90%;
    margin-left: auto;
    height: tovw(50);
    position: relative;
    z-index: 1;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    color: #fff;
    text-align: center;
  }
  button {
    position: absolute;
    width: tovw(42);
    background: transparent;
    border: 0;
    top: 50%;
    right: 1vw;
    transform: translateY(-50%);
    z-index: 1;
  }
}
.icon-faq {
  position: absolute;
  width: tovw(121);
  right: 0;
  top: 102%;
  margin: 0 !important;
}
.user-name {
  max-width: tovw(140);
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 3vw;
}
.icon-logout {
  display: inline-block;
  width: tovw(30);
  vertical-align: middle;
}
.logged {
  span {    
    line-height: 100%;
    transform: translateY(-10%);
  }
  a {
    line-height: 100%;
    transform: translateY(-20%);
  }
}
.icon-social {
  display: inline-block;
  vertical-align: middle;
  width: tovw(76);
  height: tovw(76);
  padding: .5vw;
  border: 1px solid #a0fcca;
  margin-left: .2vw;
  margin-right: .2vw;
  position: relative;
  img {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translateY(-50%);
    width: 60%;
  }
}
.dropzone {
  text-align: center;
  > div {
    height: tovw(240);
    width: tovw(240);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #a0fcca;
    &:before {
      content: "";
      position: absolute;
      width: tovw(93);
      height: tovw(93);
      background: url(/images/icon-user.png);
      background-size: 100% 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }
  &.added {
    > div {
      background: transparent;
      border: 2px solid #a0fcca;
      padding: 0;
    }
  }
  &__preview {
    height: 100%;
    text-align: center;
    position: relative;
    img {
      display: inline-block;
      width: 100%;
    }
  }
  &__plus {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__label {
    position: absolute;
    bottom: 1vw;
    left: 0;
    width: 100%;
    .added & {
      display: none;
    }
  }
}
.video-link {
  display: block;
  border: 1px solid #8bdaaf;
  width: 80%;
  margin: auto;
  padding-top: .5vw;
  padding-bottom: .5vw;
  color: #fff;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  padding-left: 1vw;
  padding-right: 1vw;
}
.vote-count {
  font-size: 1vw;
  span {
    display: block;
    padding: 0 .2vw;
    width: 12vw;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #a0fcca;
    font-size: 1.5vw;
  }
}
.profile-pic {
  img {
    display: inline-block;
    width: 100%;
    border: 2px solid #a0fcca;
  }
}
.wrap-app {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1vw;
}