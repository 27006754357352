/// Variables
/// @see https://www.smashingmagazine.com/2016/01/variables-in-css-architecture/

/// Grid
/// Using rem-units to scale for accessibility (based on the user's browser settings, default 1rem equals 16px)
/// @see layout/grid
/// grid class specified below ($breakpoints)

$grid-max-width:  120rem;
$grid-gutter:     1rem;
$grid-columns:    12;
$grid-show-outlines: false; //to show the grid's outlines

/// Breakpoints - used by the grid and media query manager
/// @type Map
/// @prop {size}: [min-width] - breakpoint name and minimum width for media queries
$breakpoints: (
  'xs': 0,
  'sm': 420px,
  'md': 720px,
  'lg': 960px,
  'xl': 1200px
);

@font-face {
  font-family: 'Agency FB';
  src: url('/fonts/AgencyFB-Bold.eot');
  src: url('/fonts/AgencyFB-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/AgencyFB-Bold.woff2') format('woff2'),
      url('/fonts/AgencyFB-Bold.woff') format('woff'),
      url('/fonts/AgencyFB-Bold.ttf') format('truetype'),
      url('/fonts/AgencyFB-Bold.svg#AgencyFB-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Cintra';
  src: url('/fonts/SVN-Cintra.eot?#iefix') format('embedded-opentype'),  url('/fonts/SVN-Cintra.otf')  format('opentype'),
	     url('/fonts/SVN-Cintra.woff') format('woff'), url('/fonts/SVN-Cintra.ttf')  format('truetype'), url('/fonts/SVN-Cintra.svg#SVN-Cintra') format('svg');
  font-weight: normal;
  font-style: normal;
}

/// mq() - media query manager
/// @see vendor/mq
/// @link https://github.com/sass-mq/sass-mq
$mq-breakpoints: $breakpoints;
$mq-base-font-size: 16px;
$mq-show-breakpoints: ();

$windowWidth: 1920;
$windowHeight: 1080;

$cdnUrl: '';

/// Fonts
/// @TODO: choose color variable naming convention
$roboto: 'Agency FB', sans-serif;

$font-size: 1.6rem;
$font-primary:    $roboto;
$font-heading: 'SVN-Cintra',sans-serif;

$golden: #cdbe91;
$golden-light: #f0e6d2;

/// Colors
$blue:  #285366;
$blue-dark: #6396b1;
$blue-light: #6f8eab;
$green: #51D66B;
$orange: #fd883e;
$red: #9c0a0a;
$yellow: #f9d126;
$purple: #1c213f;
$dark: #011627;

$white:         #fff;
$gray-light:    #e5e5e5;
$gray:          #7e7e7e;
$gray-dark:     #333;
$black:         #000;
$smoke: #7f868a;

$color-default: $golden; //default text color

$color-primary:       $golden;
$color-primary-light: $golden-light;
$color-primary-dark:  $golden;

$color-secondary:       $green;
$color-secondary-light: lighten($green, 10%);
$color-secondary-dark:  darken($green, 10%);

$color-success: green;
$color-warning: orange;
$color-alert:   red;


/// Form
$form-height:   40px;


/// Dimensions - Widths and heights
$height-header: 100px;  //example!
$height-footer: 100px;  //example!
