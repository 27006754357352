.form {
  max-width: 410px;
  margin: 0 auto;
  .error {
    color: $red;
    text-align: center;
    width: 100%;
    display: block;
  }
}
button {
  border: 0;
  outline: none !important;
}
.form-line {
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  .form-register & {
    text-align: left;
    margin-bottom: 0;
  }
  .input-bg,
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="date"],
  input[type="datalist"],
  input[type="time"],
  textarea,
  .selection {
    height: tovw(65);
    outline: none !important;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 0;
    background-color: #000000;
    transition: border-color .25s ease;
    font-size: 16px;
    text-align: center;
    text-transform: none;
    color: #fff;
    border: 2px solid #a0fcca;
    @include min-screen(1200px) {
      font-size: 1.2vw;
    }
    @include screen(800px, 860px) {
      height: 4rem;
      font-size: 1.6rem;
    }
    &:focus,
    &:active {
      border-color: #c0ffdc;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $gray;
      text-transform: uppercase;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $gray;
      text-transform: uppercase;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $gray;
      text-transform: uppercase;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $gray;
      text-transform: uppercase;
    }
    .form-register & {
      text-align: left;
      display: inline-block;
      border: 0;
      background: transparent;
      height: auto;
      width: auto;
    }
    .full-width & {
      width: 60% !important;
    }
  } 
  textarea {
    height: 10vw !important;
    line-height: 9vw !important;
    text-align: center !important;
    display: block !important;
    width: 100% !important;
    border: 2px solid #c0ffdc !important;
    background: #000 !important;
    text-transform: none !important;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $gray;
      text-transform: none;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $gray;
      text-transform: none;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $gray;
      text-transform: none;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $gray;
      text-transform: none;
    }
    &:focus,
    &:active {
      line-height: 100% !important;
      text-align: left !important;
      text-transform: none;
    }
  }
  label {    
    .form-register & {
      text-align: left;
      display: inline-block;
      font-size: tovw(20);
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
}
.input-bg {
  display: inline-block;
  line-height: tovw(65);
}