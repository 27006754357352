.btn,
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  background: url(/images/btn.png) !important;
  background-size: 100% 100% !important;
  font-size: tovw(42);
  text-transform: uppercase;
  font-weight: bold;
  color: #81bb55 !important;
  height: tovw(102);
  line-height: tovw(102);
  padding: 0 1rem;
  min-width: tovw(349);
  outline: none !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: .6rem;
  span {
    background: rgb(161,255,180);
    background: -moz-linear-gradient(0deg, rgba(161,255,180,1) 0%, rgba(42,255,236,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(161,255,180,1) 0%, rgba(42,255,236,1) 100%);
    background: linear-gradient(0deg, rgba(161,255,180,1) 0%, rgba(42,255,236,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a1ffb4",endColorstr="#2affec",GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &--long {
    height: tovw(79);
    line-height: tovw(79);
    min-width: tovw(356);
    font-size: tovw(24);
  }
  &--gradient {
    background: url(/images/btn-gradient.png) !important;
    background-size: 100% 100% !important;
    color: $dark !important;
    font-size: tovw(36);
  }
  &--fw {
    min-width: initial;
    width: 100%;
  }
  &--small {
    height: tovw(75);
    line-height: tovw(75);
    min-width: tovw(230);
    font-size: tovw(20);
  }
  &--tiny {
    height: tovw(55);
    line-height: tovw(55);
    min-width: tovw(180);
    font-size: tovw(20);
  }
  @include min-screen(1200px) {
    transition: transform .25s ease;
    &:hover {
      transform: translateY(-.2vw)
    }
  }
}
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  background: url(/images/btn-gradient.png) !important;
  background-size: 100% 100% !important;
  color: $dark !important;
  height: tovw(55);
  line-height: tovw(55);
  min-width: tovw(180);
  font-size: tovw(20);
}
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  @extend %grayscale;
}